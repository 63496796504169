import $ from 'jquery';
import logo from './logo.svg';
import img from './like.png';
import warren_img from './warren_buffet.jpeg';
import steve_img from './steve.jpeg';

import { AiFillLike, AiFillLinkedin } from "react-icons/ai";
import './App.css';
import { Component, useState } from 'react';


class App extends Component {


  constructor() {
    super();
    this.myPostsData = {
      data: []
    };
    this.bodyParser = {
      'body': '',
      'createdBy': '',
      'likes': 0
    };

    //  myPosts =  this.myPosts();
    console.log(this.myPostsData);
    this.submit = this.submit.bind(this);
    this.likeClick = this.likeClick.bind(this);
    this.postApi = this.postApi.bind(this);
  }

  async componentDidMount() {
    document.getElementById("defaultOpen").click();
    let url = "https://my-app.gupta-aakanksha123.workers.dev";

    const resp = await fetch(url, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      }
    });
    this.myPostsData.data = await resp.json();
    for (let i = 0; i < this.myPostsData.data.length; i++) {
      this.myPostsData.data[i] = JSON.parse(this.myPostsData.data[i]);
    }
    console.log(this.myPostsData);
    this.setState(this.myPostsData);
    // return resp;

  }

  async likeClick(el) {
    console.log("Like Pressed" + JSON.stringify(el));
    // var postData = JSON.stringify(el);
    el.likes = el.likes + 1;
    // this.postApi(el);

    this.myPostsData = await this.postApi(el, 'X');
    this.setState(this.myPostsData);

  }
  async openTab(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }

  async postApi(body, update) {
    let url = "https://my-app.gupta-aakanksha123.workers.dev";
    const resp = await fetch(url, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      }
    });

    let response = await resp.json();
    if (JSON.parse(response).errors.length === 0 && update === '') {

      body.key = JSON.parse(response).key;

      this.myPostsData.data.push(body);
    }
    else if (JSON.parse(response).errors.length > 0) {
      console.log(JSON.parse(response).errors);
    }
    else {
      return this.myPostsData;
    }



  }
  async submit() {

    let body = {
      "body": document.getElementsByTagName('textarea').message.value,
      'createdBy': 'Aakanksha Gupta',
      'likes': 0
    };
    this.myPostsData = await this.postApi(body, '');
    this.setState(this.myPostsData);
    window.location.reload();
  }


  render() {
    // const [value, setValue] = React.useState('');
    return (
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark ">
          <a class="navbar-brand mx-3">Cloudflare General Assignment</a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div class="container">
          <h2 class="text-center" id="title">CloudFlare ChatBox</h2>
        </div>

        <div class="container-fluid gedf-wrapper">
          <div class="row">
            <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <div class="h5">@Developer</div>
                  <div class="h7 text-muted">Fullname : Aakanksha Gupta</div>
                  <div class="h7">Developer of web applications, JavaScript, TypeScript, Node.js, Angular
                  </div>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="h6 text-muted">Followers</div>
                    <div class="h5">10</div>
                  </li>
                  <li class="list-group-item">
                    <div class="h6 text-muted">Following</div>
                    <div class="h5">100</div>
                  </li>

                </ul>
              </div>
            </div>
            <div class="col-md-6 gedf-main">
              <div class="card gedf-card">
                <div class="card-header">
                  <div class="tab">
                    <button id="defaultOpen" class="tablinks" onClick={(e) => this.openTab(e,'Posts')}>Timeline</button>
                    {/* <button class="tablinks" onClick={(e) => this.openTab(e,'Photos')}>Your Photos</button> */}
                  </div>

                </div>
                <div id="Posts" class="tabcontent" >
                  <div class="card-body">
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
                        <div class="form-group">

                          <textarea class="form-control" id="message" rows="3" placeholder="Share your thoughts with the group."></textarea>
                        </div>

                      </div>

                    </div>
                    <div class="btn-toolbar justify-content-between btnPaddingClass">
                      <div class="btn-group">
                        <button type="submit" onClick={this.submit} class="btn btn-primary submitBtnClass">Share</button>

                      </div>
                    </div>
                  </div>
               
                  <div>
                <ul class="listClass">
                  {this.myPostsData.data.map(el => (
                    <li class="listItemClass">
                      <div class="card gedf-card">
                        <div class="card-header">
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-between align-items-center">
                              <div class="mr-2">
                                <img class="rounded-circle" width="45" src="https://picsum.photos/50/50" alt="" />
                              </div>
                              <div class="ml-2">
                                <div class="h5 m-0 mx-2">{el.createdBy}</div>
                                {/* <div class="h7 text-muted">{el.createdBy}</div> */}
                              </div>
                            </div>
                            <div>

                            </div>
                          </div>

                        </div>

                        <div class="card-body">
                          <p class="card-text">
                            {el.body}
                          </p>
                        </div>
                        <div class="card-footer">
                          <div>
                            <a onClick={() => this.likeClick(el)} class="card-link"><i class="fa fa-gittip"></i> Like</a>
                            <AiFillLike />
                            ({el.likes})
                          </div>
                          {/* <a href="#" class="card-link"><i class="fa fa-comment"></i> Comment</a>
                          <a href="#" class="card-link"><i class="fa fa-mail-forward"></i> Share</a> */}
                        </div>
                      </div>
                    </li>
                  ))}

                </ul>
              </div>

                </div>

                <div id="Photos" class="tabcontent">
                   <div>hello world</div>
                </div>

              </div>


             

            </div>
            <div class="col-md-3">
              <div class="card" >
                <img src={warren_img} alt="" />
                <div class="card-body">
                  <h4>
                    <b>Warren Buffett</b>
                  </h4>
                  <p>Only buy something that you’d be perfectly happy to hold if the market shut down for 10 years</p>
                </div>
              </div>
              <div class="card">

                <img src={steve_img} alt="" />
                <div class="card-body">
                  <h4>
                    <b>Steve Jobs</b>
                  </h4>
                  <p>Remembering that you are going to die is the best way I know to avoid the trap of thinking you have something to lose. You are already naked. There is no reason not to follow your heart. </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App;
